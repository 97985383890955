import { AsyncResult, callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as DataServiceEntities from './AlternateItemsEntity.g';

export function getProductAdditionalDetailsQuery(): DataServiceQuery {
          return new DataServiceQuery();
          }

function getProductAdditionalDetailsInput (
    queryResultSettings: IQueryResultSettings, productId:
        number
): IDataServiceRequest {
  const query = getProductAdditionalDetailsQuery().resultSettings(queryResultSettings);
  return query.createDataServiceRequestForOperation(
      'GetProductAdditionalDetails',
      true,
        DataServiceEntities.ProductAdditionalDetailsExtensionClass
      ,
      'true',
      { bypassCache: 'get', returnEntity: 'DataServiceEntities.IProductAdditionalDetailsEntity' },
      {
      ProductId: productId
      }
  );
}

export function getProductAdditionalDetailsAsync (
    context: IContext, productId:
        number
    ): AsyncResult<DataServiceEntities.IProductAdditionalDetailsEntity[]> {
    const request = getProductAdditionalDetailsInput(
      context.queryResultSettings || {}, productId);
    return callActionOrExecute<DataServiceEntities.IProductAdditionalDetailsEntity[]>(request, context.callerContext);
    }