import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { IQueryResultSettings, IDataServiceRequest, IContext, AsyncResult, callActionOrExecute, DataServiceQuery } from '@msdyn365-commerce/retail-proxy';
// import {ProductSearchResult} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

export function getProductByCategoryCriteriaInput (
    queryResultSettings: IQueryResultSettings, searchCriteria:
        Entities.ProductSearchCriteria
): IDataServiceRequest {
  const query = getProductByCategoryCriteriaQuery().resultSettings(queryResultSettings);
  return query.createDataServiceRequestForOperation(
      'GetProductByCategoryCriteria',
      true, 'ProductSearchResultClass',
      'true',
      { bypassCache: 'get', returnEntity: 'ProductSearchResult' },
      {
      searchCriteria: searchCriteria
      }
  );
}

function getProductByCategoryCriteriaQuery(): DataServiceQuery {
    return new DataServiceQuery();
    }

export function getProductByCategoryCriteriaAsync (
    context: IContext, searchCriteria:
        Entities.ProductSearchCriteria
    ): AsyncResult<Entities.ProductSearchResult[]> {
    const request = getProductByCategoryCriteriaInput(
      context.queryResultSettings || {}, searchCriteria);
    return callActionOrExecute<Entities.ProductSearchResult[]>(request, context.callerContext);
    }