import { AsyncResult, callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as DataServiceEntities from './AlternateItemsEntity.g';

function getRelatedProductsQuery(): DataServiceQuery {
  return new DataServiceQuery();
  }


export function getRelatedProductsInput (
queryResultSettings: IQueryResultSettings, productId:
    number
): IDataServiceRequest {
    const query = getRelatedProductsQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
      'GetRelatedProducts',
      true,
        DataServiceEntities.ItemIdsExtensionClass
      ,
      'true',
      { bypassCache: 'get', returnEntity: 'DataServiceEntities.IItemIds' },
      {
      ProductId: productId
      }
    );
}

export function getRelatedProductsAsync (
context: IContext, productId:
    number
): AsyncResult<DataServiceEntities.IItemIds[]> {
const request = getRelatedProductsInput(
  context.queryResultSettings || {}, productId);
return callActionOrExecute<DataServiceEntities.IItemIds[]>(request, context.callerContext);
}
