import { AsyncResult, callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as DataServiceEntities from './AlternateItemsEntity.g';

export function getProductSpecificationDetailsQuery(): DataServiceQuery {
          return new DataServiceQuery();
          }

function getProductSpecificationDetailsInput (
    queryResultSettings: IQueryResultSettings, productId:
        number
): IDataServiceRequest {
  const query = getProductSpecificationDetailsQuery().resultSettings(queryResultSettings);
  return query.createDataServiceRequestForOperation(
      'GetProductSpecificationDetails',
      true,
        DataServiceEntities.ProductSpecificationsEntityExtensionClass
      ,
      'true',
      { bypassCache: 'get', returnEntity: 'DataServiceEntities.IProductSpecificationsEntity' },
      {
      ProductId: productId
      }
  );
}

export function getProductSpecificationDetailsAsync (
    context: IContext, productId:
        number
    ): AsyncResult<DataServiceEntities.IProductSpecificationsEntity[]> {
    const request = getProductSpecificationDetailsInput(
      context.queryResultSettings || {}, productId);
    return callActionOrExecute<DataServiceEntities.IProductSpecificationsEntity[]>(request, context.callerContext);
    }