const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

        {   
            const actionPath = 'actions/custom-get-sales-order-confirmation-with-hydrations.action';
            binding.dataActions[actionPath] = require('partner/actions/custom-get-sales-order-confirmation-with-hydrations.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/genera-product-list-warehouse-details.action';
            binding.dataActions[actionPath] = require('partner/actions/genera-product-list-warehouse-details.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/genera-product-warehouse-details.action';
            binding.dataActions[actionPath] = require('partner/actions/genera-product-warehouse-details.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-alternate-items-collection.action';
            binding.dataActions[actionPath] = require('partner/actions/get-alternate-items-collection.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-custom-product-additional-details.action';
            binding.dataActions[actionPath] = require('partner/actions/get-custom-product-additional-details.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-custom-product-application.action';
            binding.dataActions[actionPath] = require('partner/actions/get-custom-product-application.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-custom-product-interchange.action';
            binding.dataActions[actionPath] = require('partner/actions/get-custom-product-interchange.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-custom-product-spec.action';
            binding.dataActions[actionPath] = require('partner/actions/get-custom-product-spec.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-product-alternate-item.action';
            binding.dataActions[actionPath] = require('partner/actions/get-product-alternate-item.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-related-product-details.action';
            binding.dataActions[actionPath] = require('partner/actions/get-related-product-details.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/getProductWarehouseInfo.action';
            binding.dataActions[actionPath] = require('partner/actions/getProductWarehouseInfo.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/product-collection-eta.action';
            binding.dataActions[actionPath] = require('partner/actions/product-collection-eta.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/product-eta-action.action';
            binding.dataActions[actionPath] = require('partner/actions/product-eta-action.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'hydrators/get-inventory-in-stock-refiner.action';
            binding.dataActions[actionPath] = require('partner/hydrators/get-inventory-in-stock-refiner.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'hydrators/products-by-category.action';
            binding.dataActions[actionPath] = require('partner/hydrators/products-by-category.action');
            registerActionId(actionPath);
        }

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.packageDataActions = {};
        window.__bindings__.packageDataActions['__local__'] = {
            ...binding.dataActions
        };