// tslint:disable
import { AsyncResult, callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as DataServiceEntities from './AlternateItemsEntity.g';
import * as Msdyn365 from '@msdyn365-commerce/core';
import { Buffer } from 'buffer';

function getListProductsETAInfoQuery(): DataServiceQuery {
    return new DataServiceQuery();
    }


export function getListProductsETAInfoInput (
  queryResultSettings: IQueryResultSettings, apiConfiguration:
      DataServiceEntities.IAPIConfiguration,
      productIds:
      string[]
): IDataServiceRequest {
const query = getListProductsETAInfoQuery().resultSettings(queryResultSettings);
return query.createDataServiceRequestForOperation(
    'GetListProductsETAInfo',
    true,
      DataServiceEntities.ProductIdWiseETADetailsExtensionClass
    ,
    'true',
    { bypassCache: 'get', returnEntity: 'DataServiceEntities.IProductIdWiseETADetails' },
    {
    apiConfiguration: apiConfiguration,
      productIds: productIds
    }
);
}

export function getListProductsETAInfoAsync (
    context: IContext,ctx: Msdyn365.IActionContext,
      productIds:
      string[]
  ): AsyncResult<DataServiceEntities.IProductIdWiseETADetails[]> {
    const clientId=Buffer.from(ctx.requestContext.app.config.clientId,'utf8').toString('base64');
  const clientSecret=Buffer.from(ctx.requestContext.app.config.clientSecret,'utf8').toString('base64');
  const resource=Buffer.from(ctx.requestContext.app.config.baseUrl,'utf8').toString('base64');
  const altItemUrl=Buffer.from(ctx.requestContext.app.config.axUrl,'utf8').toString('base64');
  const loginUrl=Buffer.from(`https://login.microsoftonline.com/${ctx.requestContext.app.config.tenentId}/oauth2/token`,'utf8').toString('base64');
  const apiConfiguration:DataServiceEntities.IAPIConfiguration = { ClientId: clientId, ClientSecret: clientSecret, Resource: resource, GrantType:'client_credentials', APIUrl: altItemUrl, LoginUrl: loginUrl};
  const request = getListProductsETAInfoInput(
    context.queryResultSettings || {}, apiConfiguration, productIds);
  return callActionOrExecute<DataServiceEntities.IProductIdWiseETADetails[]>(request, context.callerContext);
  }