import { AsyncResult, callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as DataServiceEntities from './AlternateItemsEntity.g';

export function getProductApplicationQuery(): DataServiceQuery {
          return new DataServiceQuery();
          }

function getProductApplicationInput (
    queryResultSettings: IQueryResultSettings, productId:
        number
): IDataServiceRequest {
  if(!queryResultSettings.Paging)
  {
    queryResultSettings.Paging = new EntityClasses.PagingInfoClass();
    queryResultSettings.Paging.Top = 1000;
  }
  const query = getProductApplicationQuery().resultSettings(queryResultSettings);
  return query.createDataServiceRequestForOperation(
      'GetProductApplication',
      true,
        DataServiceEntities.ProductApplicationExtensionClass
      ,
      'true',
      { bypassCache: 'get', returnEntity: 'DataServiceEntities.IProductApplicationEntity' },
      {
      ProductId: productId
      }
  );
}

export function getProductApplicationInputAsync (
    context: IContext, productId:
        number
    ): AsyncResult<DataServiceEntities.IProductApplicationEntity[]> {
    const request = getProductApplicationInput(
      context.queryResultSettings || {}, productId);
    return callActionOrExecute<DataServiceEntities.IProductApplicationEntity[]>(request, context.callerContext);
    }