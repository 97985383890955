import { createObservableDataAction, IAction, IActionContext, IActionInput, versionGte } from '@msdyn365-commerce/core';
import { IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
export enum ProductListInventoryFilteringOptions {
    HideOOS = "hideOOS",
    SortOOS = "sortOOS",
    Default = "default"
}
import {getProductSearchRefinersAsync} from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

export class InventoryInStockRefinerValueInput implements IActionInput {
    public readonly actionContext: IActionContext;
    public readonly channelId: number;
    public readonly channelInventoryConfiguration: any;
    public readonly catalogId: number;

    constructor(actionContext: IActionContext, channelId: number, channelInventoryConfiguration: any, catalogId: number = 0) {
        this.actionContext = actionContext;
        this.channelId = channelId;
        this.channelInventoryConfiguration = channelInventoryConfiguration;
        this.catalogId = catalogId;
    }

    public getCacheKey = (): string => `${this.channelId}-${this.catalogId}-${this.channelInventoryConfiguration?.InventoryProductAttributeRecordId}`;
    public getCacheObjectType = (): string => 'RefinerList';
}

export const inventoryInStockRefinerValueInput = (
    actionContext: IActionContext,
    channelId:number,
    channelInventoryConfiguration: any,
    catalogId?: number | undefined
): InventoryInStockRefinerValueInput => {
    return new InventoryInStockRefinerValueInput(
        actionContext,
        channelId,
        channelInventoryConfiguration,
        catalogId
    );
};

async function getProductRefinerHierarchy(searchCriteria: any, queryResultSettings: any, ctx: any): Promise<any[]> {
    const rsVersion: string = !process.env.MSDyn365Commerce_RSVERSION || process.env.MSDyn365Commerce_RSVERSION === '--'
        ? '0.0'
        : process.env.MSDyn365Commerce_RSVERSION;
    const isHideAndSortByInventoryAvailabilitySupported: boolean = versionGte(rsVersion, '9.37');

    if (isHideAndSortByInventoryAvailabilitySupported) {
        // const productsDataActions = require('@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g');
        return getProductSearchRefinersAsync({ callerContext: ctx, queryResultSettings }, searchCriteria)
            .then((productRefiners: any) => {
                if (!productRefiners) {
                    ctx.trace('[getRefinersByCriteriaAction] returned no refiners');
                    return [];
                }
                const result: any[] = productRefiners;
                if (result.length === 0) {
                    ctx.trace('[getRefinersByCriteriaAction] returned no IProductRefinerHierarchy objects');
                    return [];
                }
                return result;
            });
    } else {
        return Promise.resolve([]);
    }
}

export async function getInventoryInStockRefiner(input: InventoryInStockRefinerValueInput, context: any): Promise<any | undefined> {
    const queryResultSettings = {
        count: true
    };
    const refiners = await getProductRefinerHierarchy({
        Context: {
            ChannelId: input.channelId,
            CatalogId: input.catalogId
        }
    }, queryResultSettings, context);

    const inventoryRefiner = input.channelInventoryConfiguration?.InventoryProductAttributeRecordId !== 0 &&
        refiners.find((refiner: any) => refiner.RecordId === input.channelInventoryConfiguration?.InventoryProductAttributeRecordId);

    let inStockRefiner: any;
    if (inventoryRefiner) {
        inStockRefiner = inventoryRefiner.Values.find((value: any) => value.LeftValueBoundString !== input.channelInventoryConfiguration.InventoryOutOfStockAttributeValueText &&
            value.RightValueBoundString !== input.channelInventoryConfiguration.InventoryOutOfStockAttributeValueText);
    }
    return inStockRefiner;
}

export const getInventoryInStockRefinerAction = createObservableDataAction({
    id: '@msdyn365-commerce/get-inventory-in-stock-refiner',
    action:<IAction<IProductRefinerHierarchy>> getInventoryInStockRefiner,
    input: inventoryInStockRefinerValueInput
});

export default getInventoryInStockRefinerAction;