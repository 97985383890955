import { AsyncResult, callActionOrExecute, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as DataServiceEntities from './AlternateItemsEntity.g';

function getProductInterchangeDetailsQuery(): DataServiceQuery {
    return new DataServiceQuery();
    }


export function getProductInterchangeDetailsInput (
  queryResultSettings: IQueryResultSettings, productId:
      number
): IDataServiceRequest {
const query = getProductInterchangeDetailsQuery().resultSettings(queryResultSettings);
return query.createDataServiceRequestForOperation(
    'GetProductInterchangeDetails',
    true,
      DataServiceEntities.ProductInterchangesEntityExtensionClass
    ,
    'true',
    { bypassCache: 'get', returnEntity: 'DataServiceEntities.IProductInterchangesEntity' },
    {
    ProductId: productId
    }
);
}

export function getProductInterchangeDetailsAsync (
  context: IContext, productId:
      number
  ): AsyncResult<DataServiceEntities.IProductInterchangesEntity[]> {
  const request = getProductInterchangeDetailsInput(
    context.queryResultSettings || {}, productId);
  return callActionOrExecute<DataServiceEntities.IProductInterchangesEntity[]>(request, context.callerContext);
  }
